/* eslint-disable max-lines */
import { defaultImport } from 'default-import'
import React, { Ref } from 'react'
import defaultStyled, { css, DefaultTheme } from 'styled-components'
import { layout, minWidth, position, space, SpaceProps } from 'styled-system'
import { SpinnerWhiteAnimation } from '../animations'
import { HiddenLabel, Text } from '../typography'
import {
  ButtonInterface,
  InnerProps,
  ButtonVariantParsedProperties,
  ButtonVariant,
  ButtonSize,
  ButtonSizeParsedProperties,
  ButtonStylesProps,
  ButtonTextProps,
} from './types'

const styled = defaultImport(defaultStyled)

function parseButtonStyles(
  theme: DefaultTheme,
  variant?: ButtonVariant
): ButtonVariantParsedProperties {
  switch (variant) {
    case 'secondary':
      return {
        background: {
          normal: theme.colors.white,
          pressed: theme.colors.white,
          hover: theme.colors.white,
          disabled: theme.colors.white,
        },
        color: {
          normal: theme.colors.primary,
          pressed: theme.colors.primaryDark,
          hover: theme.colors.primaryDarker,
          disabled: theme.colors.greyDark,
        },
        border: {
          normal: `1px solid ${theme.colors.grey}`,
          pressed: `1px solid ${theme.colors.greyDark}`,
          hover: `1px solid ${theme.colors.greyDark}`,
          disabled: `1px solid ${theme.colors.grey}`,
        },
      }
    case 'tertiary':
      return {
        background: {
          normal: theme.colors.transparent,
          pressed: theme.colors.transparent,
          hover: theme.colors.transparent,
          disabled: theme.colors.transparent,
        },
        color: {
          normal: theme.colors.blue,
          pressed: theme.colors.primaryDark,
          hover: theme.colors.primaryDarker,
          disabled: theme.colors.greyDark,
        },
        border: {
          normal: `1px solid ${theme.colors.grey}`,
          pressed: `1px solid ${theme.colors.greyDark}`,
          hover: `1px solid ${theme.colors.greyDark}`,
          disabled: `1px solid ${theme.colors.grey}`,
        },
      }
    case 'quaternary':
      return {
        background: {
          normal: theme.colors.white,
          pressed: theme.colors.white,
          hover: theme.colors.white,
          disabled: theme.colors.greyLight,
        },
        color: {
          normal: theme.colors.black,
          pressed: theme.colors.black,
          hover: theme.colors.black,
          disabled: theme.colors.grey,
        },
        border: {
          normal: `1px solid ${theme.colors.grey}`,
          pressed: `1px solid ${theme.colors.grey}`,
          hover: `1px solid ${theme.colors.greyDark}`,
          disabled: `1px solid ${theme.colors.grey}`,
        },
      }
    case 'warning':
      return {
        background: {
          normal: theme.colors.transparent,
          pressed: theme.colors.transparent,
          hover: theme.colors.transparent,
          disabled: theme.colors.transparent,
        },
        color: {
          normal: theme.colors.red,
          pressed: theme.colors.redDark,
          hover: theme.colors.redDark,
          disabled: theme.colors.greyDark,
        },
        border: {
          normal: `1px solid ${theme.colors.red}`,
          pressed: `1px solid ${theme.colors.redDark}`,
          hover: `1px solid ${theme.colors.redDark}`,
          disabled: `1px solid ${theme.colors.grey}`,
        },
      }
    case 'fainted':
      return {
        background: {
          normal: theme.colors.transparent,
          pressed: theme.colors.transparent,
          hover: theme.colors.transparent,
          disabled: theme.colors.transparent,
        },
        color: {
          normal: theme.colors.greyDark,
          pressed: theme.colors.greyDark,
          hover: theme.colors.primary,
          disabled: theme.colors.grey,
        },
        border: {
          normal: `1px solid ${theme.colors.transparent}`,
          hover: `1px solid ${theme.colors.transparent}`,
          pressed: `1px solid ${theme.colors.transparent}`,
          disabled: `1px solid ${theme.colors.transparent}`,
        },
      }
    case 'primary':
    default:
      return {
        background: {
          normal: theme.colors.primary,
          pressed: theme.colors.primaryDark,
          hover: theme.colors.primaryDarker,
          disabled: theme.colors.primaryLight,
        },
        color: {
          normal: theme.colors.white,
          pressed: theme.colors.white,
          hover: theme.colors.white,
          disabled: theme.colors.white,
        },
        border: {
          normal: `1px solid ${theme.colors.transparent}`,
          pressed: `1px solid ${theme.colors.transparent}`,
          hover: `1px solid ${theme.colors.transparent}`,
          disabled: `1px solid ${theme.colors.transparent}`,
        },
      }
  }
}

function parseButtonSizes(size?: ButtonSize): ButtonSizeParsedProperties {
  switch (size) {
    case 'sm':
      return {
        padding: '0.8rem 2.4rem',
        height: '4rem',
      }
    case 'default':
    default:
      return {
        padding: '1.1rem 2.4rem',
        height: '4.8rem',
      }
  }
}

export const Button = React.forwardRef((props: ButtonInterface, ref: Ref<HTMLButtonElement>) => {
  const {
    children,
    preFix,
    postFix,
    icon,
    variant,
    size,
    circle,
    plain,
    disabled,
    hiddenLabel,
    type = 'button',
    loading,
    loadText,
    animationSize = size === 'sm' ? 24 : 30,
    onClick,
    ...rest
  } = props
  return (
    <GeneralButton
      variant={variant}
      size={size}
      circle={circle}
      plain={plain}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {icon ? (
        <Icon>{icon}</Icon>
      ) : (
        <Inner plain={plain}>
          {preFix && !loading && <Icon mr="0.8rem">{preFix}</Icon>}
          {loading && <SpinnerWhiteAnimation size={animationSize} style={{ margin: 0 }} />}
          {children && (
            <StyledText
              ml={loading ? '0.8rem' : undefined}
              as="span"
              variant={size === 'sm' ? 'smallTextMedium' : 'textMedium'}
            >
              {loading ? loadText : children}
            </StyledText>
          )}
          {postFix && !loading && <Icon ml="0.8rem">{postFix}</Icon>}
          {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
        </Inner>
      )}
    </GeneralButton>
  )
})

Button.displayName = 'Button'

export const Inner = styled.span<InnerProps>`
  ${({ plain }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: ${plain ? 'flex-start' : 'center'};
    `
  }}
  height: 2.4rem; // set height to center the button content with or without icon the same
`

export const StyledText = styled(Text)<ButtonTextProps>`
  transition: color ${({ theme }) => theme.transitionTimes.short};
  display: block;
  text-align: center;
  white-space: nowrap;
`

export const Icon = styled.span<SpaceProps>`
  height: 2.4rem;
  width: 2.4rem;
  display: block;
  ${space}
`

export const GeneralButton = styled.button<ButtonStylesProps>`
  ${(props) => {
    const { background, color, border } = parseButtonStyles(props.theme, props.variant)

    const { padding, height } = parseButtonSizes(props.size)

    const activeStyles =
      !props.disabled &&
      css`
        color: ${color.pressed};
        background-color: ${background.pressed};
        border: ${border.pressed};
      `

    const hoverStyles =
      !props.disabled &&
      css`
        color: ${color.hover};
        background-color: ${background.hover};
        border: ${border.hover};
      `

    // plainStyles to remove some generic button styles
    const plainStyles = css`
      padding: 0;
      min-height: auto;
      min-width: auto;
      border: none;
      background-color: transparent;
    `

    const activePlainStyles = css`
      color: ${!props.disabled && color.pressed};
      background-color: transparent;
      border: none;
    `
    const hoverPlainStyles = css`
      color: ${!props.disabled && color.hover};
      background-color: transparent;
      border: none;
    `

    const disabledPlainStyles = css`
      color: ${color.disabled};
      background-color: transparent;
      border: none;
      cursor: default;
    `

    const disabledStyles = css`
      pointer-events: none;
      cursor: default;
      color: ${color.disabled};
      background-color: ${background.disabled};
      border: ${border.disabled};
      cursor: default;
    `

    // circleStyles for action buttons only with icon
    const circleStyles = css`
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 3.2rem;
      width: 3.2rem;
      min-width: auto;
      padding: 0;
      border-radius: 4rem;

      & svg {
        vertical-align: middle;
      }
    `

    return css`
      display: inline-block;
      color: ${color.normal};
      background-color: ${background.normal};
      border-radius: 0.8rem;
      border: ${border.normal};
      padding: ${props.plain ? '0.5rem' : padding};
      height: ${props.plain ? '2.4rem' : height};
      transition: color ${props.theme.transitionTimes.short},
        border ${props.theme.transitionTimes.short},
        background-color ${props.theme.transitionTimes.short};
      ${props.plain && plainStyles};
      ${props.circle && circleStyles};

      ${StyledText} {
        color: ${color.normal};
      }

      /* States */

      &:hover {
        ${props.plain ? hoverPlainStyles : hoverStyles};

        ${StyledText} {
          color: ${color.hover};
        }
      }

      &:active {
        ${props.plain ? activePlainStyles : activeStyles};

        ${StyledText} {
          color: ${color.pressed};
        }
      }

      &:disabled {
        ${props.plain ? disabledPlainStyles : disabledStyles};

        ${StyledText} {
          color: ${color.disabled};
        }
      }
    `
  }};
  user-select: none;
  ${space};
  ${minWidth};
  ${layout};
  ${position};
`
