import React from 'react'

export const AvatarsSmIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 13C1.25 12.5858 1.58579 12.25 2 12.25H14C14.4142 12.25 14.75 12.5858 14.75 13C14.75 13.4142 14.4142 13.75 14 13.75H2C1.58579 13.75 1.25 13.4142 1.25 13Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 9.5C7.65685 9.5 9 8.15685 9 6.5C9 4.84315 7.65685 3.5 6 3.5C4.34315 3.5 3 4.84315 3 6.5C3 8.15685 4.34315 9.5 6 9.5ZM6 11C8.48528 11 10.5 8.98528 10.5 6.5C10.5 4.01472 8.48528 2 6 2C3.51472 2 1.5 4.01472 1.5 6.5C1.5 8.98528 3.51472 11 6 11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7001 3.58283C10.7653 3.61881 10.8345 3.64866 10.9059 3.67152C11.3282 3.80679 11.718 4.03594 12.0444 4.34565C12.5574 4.83243 12.8798 5.48627 12.9537 6.18956C13.0276 6.89285 12.8482 7.59944 12.4476 8.18223C12.1928 8.55303 11.8591 8.85823 11.4741 9.07835C11.4091 9.11555 11.3477 9.15912 11.2913 9.20789C11.2904 9.20867 11.2895 9.20946 11.2886 9.21025C11.2791 9.21851 11.2697 9.22693 11.2605 9.23549C11.0181 9.46098 10.8803 9.78545 10.9667 10.1077C11.076 10.5158 11.4996 10.7638 11.8833 10.587C12.6148 10.2499 13.247 9.72034 13.7086 9.04884C14.3154 8.16581 14.5873 7.09523 14.4753 6.02963C14.3633 4.96404 13.8748 3.97337 13.0976 3.23582C12.5065 2.67494 11.7781 2.28844 10.9924 2.11079C10.5803 2.01762 10.2176 2.34821 10.1955 2.77013C10.178 3.10333 10.3802 3.39206 10.6642 3.56222C10.675 3.56868 10.6859 3.57497 10.6969 3.58108C10.698 3.58167 10.699 3.58225 10.7001 3.58283Z"
    />
  </svg>
)
