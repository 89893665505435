import React from 'react'

export const LockLgIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11H7C6.44772 11 6 11.4477 6 12V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V12C18 11.4477 17.5523 11 17 11ZM7 9C5.34315 9 4 10.3431 4 12V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V12C20 10.3431 18.6569 9 17 9H7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13C12.5523 13 13 13.4477 13 14V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V14C11 13.4477 11.4477 13 12 13Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V11H15V7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7V11H7V7Z"
      />
    </svg>
  )
}
