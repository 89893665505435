import React from 'react'

export const AvatarsLgIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 18C4 17.4477 4.44772 17 5 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13ZM10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7425 12.4421C17.0251 12.2404 17.2727 11.9898 17.4724 11.6992C17.877 11.1105 18.0582 10.3968 17.9836 9.68641C17.9089 8.97601 17.5832 8.31557 17.0651 7.82387C16.9172 7.68357 16.7565 7.55964 16.5856 7.45328C16.44 7.36263 16.2871 7.28474 16.1286 7.22039C16.0624 7.1935 15.9975 7.16247 15.9346 7.12762C15.877 7.09568 15.8212 7.06052 15.7678 7.02242C15.4291 6.78075 15.1876 6.42024 15.2093 6.00548C15.2382 5.45395 15.7137 5.02045 16.2485 5.15836C16.464 5.21395 16.6753 5.2838 16.881 5.36732C17.0073 5.41858 17.1314 5.47499 17.2531 5.53642C17.6155 5.71937 17.9564 5.94681 18.2672 6.2151C18.3265 6.2663 18.3847 6.31898 18.4418 6.37312C19.3053 7.19262 19.8482 8.29336 19.9726 9.47735C20.097 10.6613 19.7949 11.8509 19.1206 12.832C18.7642 13.3506 18.3161 13.793 17.8031 14.1403C17.6193 14.2648 17.4271 14.3771 17.2278 14.4763C16.7334 14.7223 16.1782 14.3972 16.0353 13.8637C15.9278 13.4625 16.089 13.0597 16.3701 12.7529C16.4144 12.7045 16.4617 12.6585 16.5114 12.6153C16.5656 12.5681 16.6227 12.5243 16.6819 12.4842C16.7022 12.4704 16.7224 12.4564 16.7425 12.4421Z"
    />
  </svg>
)
