import { ReactElement, Dispatch, SetStateAction } from 'react'
import { SpaceProps } from 'styled-system'

export enum TabsVariant {
  UNDERLINE = 'underline',
}

export interface TabsProps extends SpaceProps {
  children: ReactElement | ReactElement[]
  initialTab?: number // in case the default selected Tab needs to be not the first one
  variant?: TabsVariant
  listMarginBottom?: string
}

export interface TabProps extends SpaceProps {
  tabTitle: string
  children: ReactElement | ReactElement[]
}

export interface TabListProps {
  variant?: TabsVariant
  listMarginBottom?: string
}

export interface TabTitleProps {
  tabTitle: string
  index: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  isSelected?: boolean
  variant?: TabsVariant
}

export interface TabButtonProps {
  isSelected?: boolean
  variant?: TabsVariant
}
