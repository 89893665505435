import React from 'react'

export const LockSmIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.5H4C3.72386 6.5 3.5 6.72386 3.5 7V13C3.5 13.2761 3.72386 13.5 4 13.5H12C12.2761 13.5 12.5 13.2761 12.5 13V7C12.5 6.72386 12.2761 6.5 12 6.5ZM4 5C2.89543 5 2 5.89543 2 7V13C2 14.1046 2.89543 15 4 15H12C13.1046 15 14 14.1046 14 13V7C14 5.89543 13.1046 5 12 5H4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.25C8.41421 8.25 8.75 8.58579 8.75 9V11C8.75 11.4142 8.41421 11.75 8 11.75C7.58579 11.75 7.25 11.4142 7.25 11V9C7.25 8.58579 7.58579 8.25 8 8.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 4C4.25 1.92893 5.92893 0.25 8 0.25C10.0711 0.25 11.75 1.92893 11.75 4V6H10.25V4C10.25 2.75736 9.24264 1.75 8 1.75C6.75736 1.75 5.75 2.75736 5.75 4V6H4.25V4Z"
      />
    </svg>
  )
}
