import React from 'react'

export const TextLgIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6506 22.3912C18.8433 22.669 17.9486 22.4036 17.4233 21.7305L15.3768 19.1081H6C3.79086 19.1081 2 17.3172 2 15.1081V7C2 4.79086 3.79086 3 6 3H17C19.2091 3 21 4.79086 21 7V20.5C21 21.3538 20.458 22.1135 19.6506 22.3912ZM4 15.1081V7C4 5.89543 4.89543 5 6 5H17C18.1046 5 19 5.89543 19 7V20.5L16.6532 17.4929C16.4637 17.2501 16.1729 17.1081 15.8649 17.1081H6C4.89543 17.1081 4 16.2127 4 15.1081Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9C6 8.44772 6.44772 8 7 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H7C6.44772 10 6 9.55228 6 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13C6 12.4477 6.44772 12 7 12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H7C6.44772 14 6 13.5523 6 13Z"
      />
    </svg>
  )
}
