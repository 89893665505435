import React, { Children, FC, useState } from 'react'
import { TabList, TabsContainer } from './styled'
import { TabTitle } from './tab-title'
import { TabsProps } from './types'

export const Tabs: FC<TabsProps> = ({
  children,
  initialTab,
  variant,
  listMarginBottom,
  ...spaceProps
}) => {
  const [selectedTab, setSelectedTab] = useState(initialTab || 0)

  return (
    <TabsContainer {...spaceProps}>
      <TabList variant={variant} listMarginBottom={listMarginBottom}>
        {Children.map(children, (child, index: number) => {
          const { tabTitle } = child.props
          return (
            <TabTitle
              key={tabTitle}
              tabTitle={tabTitle}
              index={index}
              setSelectedTab={setSelectedTab}
              isSelected={selectedTab === index}
              variant={variant}
            />
          )
        })}
      </TabList>
      {React.Children.toArray(children)[selectedTab]}
    </TabsContainer>
  )
}
