import React from 'react'

export const TextSmIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
        <path d="M14.5 15.415a1.5 1.5 0 0 1-1.668-.473l-1.667-2.068H3a2.5 2.5 0 0 1-2.5-2.5V3A2.5 2.5 0 0 1 3 .5h10A2.5 2.5 0 0 1 15.5 3v11a1.5 1.5 0 0 1-1 1.415ZM14 3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v7.374a1 1 0 0 0 1 1h8.404a1 1 0 0 1 .779.372L14 14V3Z" />
        <path d="M3.25 5A.75.75 0 0 1 4 4.25h8a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 5ZM3.25 8A.75.75 0 0 1 4 7.25h5a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 8Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
